import tw from 'tailwind-styled-components';
import styled, { createGlobalStyle } from 'styled-components';
import { ReactNode } from 'react';

export const GlobalStyle = createGlobalStyle`
  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-Index: 55;
    display: flex;
    align-items: center;
  }
  .content {
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-Right: -50%;
    background-color: transparent;
    border: 0;
    padding: 0 0 10%;
    text-align: center;
    inset: 55% auto auto 50%;
  }
`;

export const ModelContainer = styled.div<{
  children?: ReactNode;
  className?: string;
}>`
  div {
    height: 100%;
  }
`;
ModelContainer.displayName = 'ModelContainer';

export const Close = styled.button<{
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}>`
  svg {
    height: 31px;
    width: 31px;
  }
`;
Close.displayName = 'Close';

export const CloseBtn = tw.span`
    mx-auto
    inline-block
    cursor-pointer
`;
CloseBtn.displayName = 'CloseBtn';

export const TitleWrapper = styled.div<{
  children?: ReactNode;
  className?: string;
}>`
  padding-bottom: 15px;
`;
TitleWrapper.displayName = 'TitleWrapper';
